import createKindeClient from '@kinde-oss/kinde-auth-pkce-js';
let kinde;

export async function initializeKindeClient() {
  kinde = await createKindeClient({
    client_id: import.meta.env.VITE_KINDE_CLIENT_ID,
    domain: import.meta.env.VITE_KINDE_DOMAIN,
    redirect_uri: import.meta.env.VITE_KINDE_REDIRECT_URL,
    on_redirect_callback: (user, appState) => {
      /*const appStore = useAppStore();
      const authStore = useAuthStore();

      if (user) {
        authStore.isAuthenticated = true;
        authStore.userProfile = user;
        // Handle post-login state
      }
      if (appState?.redirectTo) {
        authStore.redirectTo = appState?.redirectTo;
      }*/
    },
    //is_dangerously_use_local_storage: true,
  });
}

export function getKindeClient() {
  return kinde;
}
