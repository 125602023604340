//import { useAuthStore } from '@/stores/auth';
export default async function ({ to, next, store }) {
  const isDev = process.env.NODE_ENV === 'development' ? true : false;
  if (isDev) {
    //return next();
  }

  if (!store.isAuthenticated) {
    return next({ path: '/login' });
  }

  return next();
}
