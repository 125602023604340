<template>
  <div>
    <Teleport to="body">
      <Toast
        :baseZIndex="9999"
        :pt="{
          messageText: {
            class: [
              // Font and Text
              '!leading-7',
            ],
          },
        }"
        :pt-options="{ mergeProps: true }"
      />
      <ConfirmDialog
        group="alertConfirmation"
        :draggable="false"
        class="w-5/6 md:w-4/6 xl:w-1/2 2xl:w-2/5"
      >
        <template #message="slotProps">
          <Message :severity="slotProps.message?.severity ?? 'warn'">
            <template #icon>
              <i :class="slotProps.message.icon"></i>
            </template>
            <span class="ml-2">{{ slotProps.message.message }}</span>
          </Message>
        </template>
      </ConfirmDialog>
      <ConfirmDialog
        group="basic"
        :draggable="false"
        class="w-5/6 p-4 md:w-4/6 xl:w-1/2 2xl:w-2/5"
      >
        <template #message="slotProps">
          <div
            v-html="slotProps.message.message"
            v-if="'html' === slotProps.message.type"
          ></div>
          <div v-else>
            {{ slotProps.message.message }}
          </div>
        </template>
      </ConfirmDialog>
      <ConfirmDialog
        group="headless"
        :draggable="false"
      >
        <template #container="{ message, rejectCallback }">
          <div class="flex flex-col items-start rounded bg-warn-100 p-8 text-warn">
            <div class="flex w-full items-center justify-between">
              <div class="mb-2 mt-6 block flex-1 text-lg font-semibold">{{ message.header }}</div>
            </div>

            <div
              v-html="message.message"
              v-if="'html' === message.type"
            ></div>
            <div v-else>
              {{ message.message }}
            </div>
            <div class="mt-6 flex w-full items-center justify-end gap-2">
              <Button
                :label="message.rejectProps.label"
                :size="message.rejectProps.size"
                severity="secondary"
                outlined
                @click="rejectCallback"
                class="w-32"
              ></Button>
            </div>
          </div>
        </template>
      </ConfirmDialog>
      <Dialog
        v-model:visible="isOpen"
        modal
        :draggable="false"
        :closable="isDesktop ? true : false"
        :header="title"
        :showHeader="true"
        :class="[...sizeClasses]"
      >
        <template #header>
          <div class="w-full">
            <div v-if="!isDesktop">
              <div class="flex justify-end">
                <button
                  class="h-10 w-10 rounded-full text-2xl text-surface-400 hover:bg-primary-300"
                  @click="isOpen = false"
                >
                  &times;
                </button>
              </div>
            </div>
            <div
              class="flex w-full flex-col items-start justify-center gap-y-3 lg:flex-row lg:items-center lg:justify-start lg:gap-x-3"
            >
              <div
                class="flex-grow-0 text-xl font-bold"
                :class="[...titleClasses]"
              >
                <i :class="modalContentProperties?.titleIcon"></i> {{ title }}
              </div>
              <template
                v-if="(modalContentProperties?.tags ?? null) && modalContentProperties.tags.length"
              >
                <Tag
                  v-for="item in modalContentProperties.tags"
                  :key="item.id"
                  :value="item.name"
                  :severity="item.severity"
                  :pt="{
                    root: ({ props, state, parent }) => ({
                      class: [
                        '!text-[.875rem] !lg:text-base lowercase whitespace-nowrap font-normal',
                      ],
                    }),
                  }"
                  :pt-options="{ mergeProps: true }"
              /></template>
            </div>
          </div>
        </template>
        <component
          :is="modalContentView"
          :modal-content-properties="modalStore.modalContentProperties"
          v-bind="modalStore.modalContentProperties"
          :key="modalContentKey"
        >
        </component>
      </Dialog>
    </Teleport>
    <AppLayout>
      <router-view></router-view>
    </AppLayout>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useTitle, breakpointsTailwind, useBreakpoints } from '@vueuse/core';

import { useAppStore } from '@/stores/app';
import { useModalStore } from '@/stores/modal';
import AppLayout from '@/components/AppLayout.vue';

const metaTitle = useTitle();

const breakpoints = useBreakpoints(breakpointsTailwind);
const isDesktop = breakpoints.greaterOrEqual('xl');
const isLargeDesktop = breakpoints.greaterOrEqual('2xl');
const isXLargeDesktop = breakpoints.greaterOrEqual('3xl');

const appStore = useAppStore();
const { appName } = storeToRefs(appStore);
const modalStore = useModalStore();
const {
  isOpen,
  modalContentView,
  modalContentKey,
  modalContentProperties,
  sizeClasses,
  title,
  titleClasses,
  btnCloseEnabled,
} = storeToRefs(modalStore);

onMounted(() => {
  metaTitle.value = appName.value;
});
</script>

<style></style>
