import { useAuthStore } from '@/stores/auth';
import axios from 'axios';
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_API, // url = base url + request url
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});
// request interceptor
axiosInstance.interceptors.request.use(
  async config => {
    const authStore = useAuthStore(); // Get the auth store instance
    const accessToken = authStore.accessToken; // Access the accessToken from the store
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  async response => {
    const res = response?.data ?? null;
    if (response.status != 200 && response.status != 201 && response.status != 204) {
      return Promise.reject(new Error(response.message || 'Error'));
    } else {
      return res;
    }
  },
  async error => {
    const {
      config,
      response: { status },
    } = error;
    // const originalRequest = error.config
    //originalRequest.url.includes('/authentification/refresh')
    // await store.dispatch('refreshAccessToken')
    // return service(originalRequest)
    // console.log(error);
    // if (status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true
    //   store
    //     .dispatch('refreshAccessToken')
    //     .then(() => {
    //       return service(config)
    //     })
    //     .catch(async (err) => {
    //       await store.dispatch('clearUserData')
    //       window.location.reload()
    //     })

    //   //
    //   // window.location.reload()
    //   // return Promise.reject(error)
    // }
    return Promise.reject(error);
  }
);
export default axiosInstance;
