import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false);
  const userProfile = ref(null);
  const accessToken = ref(null);
  const redirectTo = ref(null);

  const logout = () => {
    isAuthenticated.value = false;
    userProfile.value = null;
    accessToken.value = null;
    redirectTo.value = null;
  };

  return {
    isAuthenticated,
    userProfile,
    accessToken,
    redirectTo,
    logout,
  };
});
